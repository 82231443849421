import Vue from 'vue'
export const essilorPatientOut = async (attendance, clinicId) => {
  if (attendance.type === 'ESSILOR_ATTENDANCE') {
    try {
      await Vue.prototype.api.essilorPatientOut({
        clinic_id: clinicId,
        appointment_item_ids: attendance.appointment.appointment_items.filter(item => {
          return item.attendance_id === attendance.id
        }).map(item => item.id)
      })
    } catch (err) {
      Vue.prototype.$toast.error(err.message)
    }
  }
}