<template>
  <div
    class="my-4 mr-3"
    v-if="canEdit"
  >
    <b-row class="d-flex justify-content-end">
      <b-button
        v-if="!hideFinishLater && attendance?.type === 'ATTENDANCE'"
        variant="link"
        :disabled="!canEdit"
        v-b-modal.pause-modal
        >
        Finalizar depois
      </b-button>
      <b-button
        v-if="['ATTENDANCE', 'SURGERY', 'EXAM','MEDICAL_REPORT','ESSILOR_ATTENDANCE'].includes(attendance?.type)"
        variant="outline-success"
        class="m-2 font-weight-bold font-size"
        :disabled="!canEdit || disabled"
        v-b-modal.attendance-review-modal
      >
        Finalizar e imprimir
      </b-button>
      <b-button
        variant="success"
        class="m-2"
        :disabled="!canEdit || disabled"
        @click="onFinishAttendance"
      >
        {{ (certificate && certificate.active === true && attendance?.type !== 'PRE_CONSULTATION') ? 'Finalizar e assinar' : 'Finalizar' }}
      </b-button>
    </b-row>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { essilorPatientOut } from '@/utils/essilor/essilorIntegrationHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  props: {
    noCid: Boolean,
    hideFinishLater: Boolean,
    disabled: Boolean,
  },
  computed: {
    ...mapGetters(['certificate']),
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      attendance: state => state.attendance.attendance,
      modules: state => state.attendance.form,
    })
  },
  data() {
    return {
      surgeryId: this.$route.params.surgeryId,
      clinic: getCurrentClinic()
    }
  },
  methods: {
    ...mapActions('attendance', ['finishAttendance']),
    async onFinishAttendance() {
      if (
        this.attendance.type === 'PRE_CONSULTATION'
        && !this.hasCompletedModule()
      ) {
        this.$toast.warning('Preencha algum campo para finalizar a pré-consulta')
        return
      }

      await essilorPatientOut(this.attendance, this.clinic.id)
      const finished = await this.finishAttendance({ noCid: this.noCid })
      if (this.surgeryId && finished) 
        this.$router.push(`/centro-cirurgico/dashboard/${this.surgeryId}`)

      this.$emit('onFinishAttendance')
    },
    hasCompletedModule () {
      return Object.keys(this.modules).some(key=> {
          return (
            this.modules[key]?.completed
            && this.modules[key]?.scopes.some(scope => {
              return scope === 'PRE_CONSULTATION'
            })
          )
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.font-size {
  font-size: min(1.2vw, 14px);
}
</style>
